import { useContext, useEffect, useRef, useState } from "react";
import shareIcon from "../../assets/share.svg";
import searchIcon from "../../assets/search-share.svg";
import axios from "axios";
import { DataContext } from "../../context/DataContext";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function Share({
	directoryId,
	actionsDirectoryRef,
	shareList,
	setShowShare,
}) {
	const [searchUsersInput, setSearchUsersInput] = useState("");
	const [users, setUsers] = useState([]);
	const { setMessage } = useContext(DataContext);
	const shareListInput = useRef();

	async function share(directoryId, recipientUser, access) {
		try {
			const { data } = await axios.post(`/workspace/share/${directoryId}`, {
				user: recipientUser,
				access: access || "view",
			});

			if (data.success) {
				setMessage({
					error: false,
					message: data.message,
				});
			} else {
				setMessage({
					error: true,
					message: data.message,
				});
			}
		} catch (error) {
			setMessage({
				error: true,
				message: ERROR_MESSAGE,
			});
		}
	}

	useEffect(() => {
		function updatePosition() {
			const boundingRect = actionsDirectoryRef.current?.getBoundingClientRect();

			if (boundingRect && shareList.current) {
				shareList.current.style.top = `${boundingRect.top}px`;
				shareList.current.style.left = `${
					boundingRect.left - shareList.current.offsetWidth + 10
				}px`;
			}
		}

		updatePosition();

		async function searchUsers() {
			const q = searchUsersInput.trim().toLowerCase();
			if (q.length === 0) {
				setUsers([]);
				return;
			}

			try {
				const { data } = await axios.get(`/users/search?q=${q}`);
				if (data.success) {
					setUsers(data.data.users);
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		searchUsers();
	}, [searchUsersInput]);

	return (
		<ul
			ref={shareList}
			className="share-list"
			onMouseLeave={() => {
				setSearchUsersInput("");
				setUsers([]);
				shareListInput.current.value = "";
				setShowShare(false);
			}}
		>
			<li id="search-users">
				<img src={searchIcon} alt="search" />
				<input
					autoFocus={true}
					ref={shareListInput}
					placeholder="Search"
					onChange={(e) => {
						setSearchUsersInput(e.target.value);
					}}
				/>
			</li>
			{users.map((user, index) => {
				return (
					<li
						key={index}
						style={{ fontSize: "13px" }}
						onClick={() => {
							share(directoryId, user._id);
							setShowShare(false);
						}}
					>
						{user.email}
						<img src={shareIcon} alt="Share" />
					</li>
				);
			})}
		</ul>
	);
}
