import { Fragment, useContext, useRef } from "react";
import { DataContext } from "../../context/DataContext";
import "./style.css";

export default function LoadingUsersState() {
	const dirColumns = useRef(["Email", "Full Name", "Role", "Last Updated"]);

	const { limit } = useContext(DataContext);
	const rows = useRef(Array.from({ length: limit }));

	return (
		<Fragment>
			<h3 id="sharing-heading">Users sharing with you:</h3>
			<table className="sharing-user-workspace">
				<thead>
					<tr>
						{dirColumns.current.map((column, index) => (
							<th
								key={index}
								style={{
									width: index === dirColumns.length - 1 ? "auto" : "100px",
									textAlign: index === dirColumns.length - 1 ? "right" : "left",
								}}
							>
								{column}
							</th>
						))}
					</tr>
				</thead>
			</table>
			{rows.current.map((_, index) => (
				<div className="loading-state" key={index}>
					<div
						className="loader"
						style={{ animationDelay: `${index / 100}s` }}
					></div>
				</div>
			))}
		</Fragment>
	);
}
