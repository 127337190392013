import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DataContext } from "../../context/DataContext";
import Message from "../../components/Message/Message";

export default function InvitationPage() {
	const code = useRef(new URLSearchParams(window.location.search).get("code"));
	const [success, setSuccess] = useState(false);
	const { message, setMessage } = useContext(DataContext);
	const navigate = useNavigate();

	useEffect(() => {
		async function acceptInvitation() {
			try {
				const { data } = await axios.post(
					`/directory/invitation/accept/${code.current}`
				);

				if (data.success) {
					setMessage({
						error: false,
						message: data.message,
					});
					navigate(`/home/${data.data.id}`);
					setSuccess(true);
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
					setSuccess(false);
					navigate("/home");
				}
			} catch (error) {
				setMessage({
					error: true,
					message: "An error occurred while accepting the invitation.",
				});
				setSuccess(false);
			}
		}

		acceptInvitation();
	}, []);

	return (
		<Fragment>
			{message && <Message message={message} setMessage={setMessage} />}
		</Fragment>
	);
}
