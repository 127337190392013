import "./style.css";
import React, { Fragment, useContext } from "react";
import { useState } from "react";
import axios from "axios";
import petalStudioIcon from "../../assets/petalstudio.svg";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import Message from "../../components/Message/Message";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function ForgotPassword() {
	const [email, setEmail] = useState("");
	const { message, setMessage } = useContext(DataContext);

	async function handleForgotPassword(e) {
		e.preventDefault();

		if (!email) {
			setMessage({
				error: true,
				message: "Please enter your email",
			});
			return;
		}

		try {
			const { data } = await axios.get(`/forgot-password?email=${email}`);

			if (data.success) {
				setMessage({
					error: false,
					message: data.message,
				});
			} else {
				setMessage({
					error: true,
					message: data.message,
				});
			}
		} catch (error) {
			setMessage({
				error: true,
				message: ERROR_MESSAGE,
			});
		}
	}

	return (
		<Fragment>
			{message && <Message message={message} setMessage={setMessage} />}
			<form id="forgot-password-form" onSubmit={(e) => handleForgotPassword(e)}>
				<Link to={"/"} id="login-logo">
					<img src={petalStudioIcon} alt="logo" />
					<span>Petal Studio</span>
				</Link>

				<h1>Forgot Password</h1>

				<input
					autoFocus
					type="email"
					placeholder="Email"
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<div id="forgot-password">
					<Link to={"/login"}>Login?</Link>
				</div>
				<button>Get Link</button>
			</form>
		</Fragment>
	);
}
