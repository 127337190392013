import { Fragment, useContext, useEffect, useRef, useState } from "react";
import directoryIcon from "../../assets/directory.svg";
import actionIcon from "../../assets/action.svg";
import ActionsDirectory from "../../components/Actions/ActionsDirectory";
import Pagination from "../../components/Tools/Pagination";
import Nav from "../../components/Nav/Nav";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import "./style.css";
import Profile from "../../components/profile/Profile";
import ManageAccess from "../../components/ManageAccess/ManageAccess";
import Move from "../../components/Move/Move";
import { useNavigate, useParams } from "react-router-dom";
import { ERROR_MESSAGE } from "../../Constants/errorMessages.js";
import httpStatusCodes from "../../Constants/httpStatusCodes";
import Message from "../../components/Message/Message.js";

export default function SharedContents() {
	const dirColumns = useRef([
		"Select",
		"Name",
		"Creator",
		"Shared",
		"Created At",
		"Actions",
	]);

	const profileLocation = useRef(null);

	const {
		setDirectoryId,
		setSelectedFile,
		selectedDirectories,
		setMessage,
		message,
		setSelectedDirectories,
		actionPosition,
		setActionPosition,
		totalPages,
		page,
		setPage,
	} = useContext(DataContext);
	const [sharedDirectories, setSharedDirectories] = useState([]);
	const [showDirectoryActions, setShowDirectoryActions] = useState(false);
	const [showManageAccess, setShowManageAccess] = useState(false);
	const [showProfile, setShowProfile] = useState(false);
	let { id: userId } = useParams();
	const [showMove, setShowMove] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		async function getSharedDirectories() {
			try {
				const { data } = await axios.get(`/workspace/users/${userId}`);

				if (data.statusCode === httpStatusCodes.OK) {
					setSharedDirectories(data.data.directories);
				} else if (data.statusCode === httpStatusCodes.UNAUTHORIZED) {
					setMessage({
						error: true,
						message: data.message,
					});
				}

				if (data.statusCode >= 400) {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		getSharedDirectories();
	}, []);

	return (
		<Fragment>
			<Nav />
			{message && <Message message={message} setMessage={setMessage} />}
			{showManageAccess && (
				<ManageAccess setShowManageAccess={setShowManageAccess} />
			)}
			{showMove && <Move setShowMove={setShowMove} />}
			{showDirectoryActions && (
				<ActionsDirectory
					X={actionPosition.X}
					Y={actionPosition.Y}
					setShowManageAccess={setShowManageAccess}
					setShowMove={setShowMove}
					setShowDirectoryActions={setShowDirectoryActions}
				/>
			)}

			{showProfile && (
				<Profile
					userId={userId}
					X={profileLocation.current.X}
					Y={profileLocation.current.Y}
				/>
			)}

			<div className="table-container">
				{/* ======================= NOTHING TO SHOW ======================= */}
				{sharedDirectories.length === 0 && (
					<div className="nothing-to-show">
						<p>Nothing to show here</p>
					</div>
				)}
				{/* ======================= DIRECTORY EXP ======================= */}
				{sharedDirectories.length > 0 && (
					<Fragment>
						<Pagination
							title={"Directory"}
							totalPages={totalPages}
							page={page}
							setPage={setPage}
							selectedDirectories={selectedDirectories}
						/>
						<table className="explorer directories">
							<thead>
								<tr>
									{dirColumns.current.map((column, index) => {
										let width =
											index === dirColumns.current.length - 1
												? "auto"
												: "100px";

										if (index === 0) {
											width = "10px";
										}

										if (index === 1 && window.innerWidth <= 800) {
											width = "500px";
										}

										return (
											<th
												className={`${
													index !== 0 &&
													index !== 1 &&
													index !== dirColumns.current.length - 1
														? "hide-on-mobile"
														: ""
												}`}
												key={index}
												style={{
													width: width,
													textAlign:
														index === dirColumns.current.length - 1
															? "right"
															: "left",
												}}
											>
												{index === 0 ? (
													<input
														style={{ cursor: "pointer" }}
														type="checkbox"
														onClick={(e) => {
															e.target.checked
																? setSelectedDirectories(
																		sharedDirectories.map((dir) => dir._id)
																  )
																: setSelectedDirectories([]);
														}}
													/>
												) : (
													column
												)}
											</th>
										);
									})}
								</tr>
							</thead>

							<tbody>
								{sharedDirectories.map((directory) => {
									return (
										<tr
											className={`${
												selectedDirectories.includes(directory._id)
													? "selected-item"
													: ""
											} ${directory.pinned ? "pinned" : ""}`}
											key={directory._id}
											onDoubleClick={() => {
												navigate(`/home/${directory._id}`);
											}}
											onClick={() => {
												if (selectedDirectories.length > 0) {
													if (selectedDirectories.includes(directory._id)) {
														setSelectedDirectories(
															selectedDirectories.filter(
																(id) => id !== directory._id
															)
														);
													} else {
														setSelectedDirectories((prev) => {
															return [...prev, directory._id];
														});
													}
												}
											}}
										>
											<td>
												<input
													type="checkbox"
													style={{ cursor: "pointer" }}
													onChange={(e) => {
														if (e.target.checked) {
															setSelectedDirectories((prev) => {
																return [...prev, directory._id];
															});
														} else {
															const newSelected = selectedDirectories.filter(
																(id) => id !== directory._id
															);
															setSelectedDirectories(newSelected);
														}
													}}
													checked={selectedDirectories.includes(directory._id)}
												/>
											</td>
											<td
												style={{
													width: "200px",
													cursor: "pointer",
													display: "flex",
													alignItems: "center",
												}}
												title={directory.directoryName}
											>
												<img src={directoryIcon} alt="Directory" />
												<p
													style={{
														width: "100%",
														overflow: "scroll",
													}}
												>
													{directory.directoryName}
												</p>
											</td>
											<td className="hide-on-mobile">
												{directory.creatorEmail}
											</td>
											<td className="hide-on-mobile">
												<div
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
													}}
												>
													{directory?.shared.length === 0
														? "-"
														: directory.shared
																.slice(0, 3)
																.map((user, index) => {
																	const bg = ["#1CDC52", "#1C93DC", "#DCA61C"];
																	const style = {
																		borderRadius: "50%",
																		width: "25px",
																		height: "25px",
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		background: bg[index],
																		color: "white",
																		transform: `translateX(-${index * 10}px)`,
																		cursor: "pointer",
																	};
																	return (
																		<div
																			key={index}
																			style={style}
																			onMouseEnter={(e) => {
																				profileLocation.current = {
																					X: e.clientX,
																					Y: e.clientY,
																				};
																				userId = user.user;
																				setShowProfile(true);
																			}}
																			onMouseLeave={() => {
																				setShowProfile(false);
																			}}
																		>
																			{user.email[0].toUpperCase()}
																		</div>
																	);
																})}
												</div>
											</td>
											<td
												className="hide-on-mobile"
												style={{
													color: "#616161",
												}}
											>
												{new Date(directory.createdAt).toLocaleDateString()}
											</td>
											<td>
												<img
													style={{
														marginLeft: "calc(100% - 20px)",
														marginTop: "10px",
													}}
													onClick={(e) => {
														setActionPosition(() => ({
															X: e.clientX - 240,
															Y: e.clientY + 20,
														}));
														setShowDirectoryActions(true);
														setSelectedFile(null);
														setDirectoryId(directory._id);
													}}
													src={actionIcon}
													className="actions-icon"
													alt="action icon"
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
}
