import closeIcon from "../../assets/cross.svg";
import "./style.css";
import tickIcon from "../../assets/tick.svg";
import errorIcon from "../../assets/error.svg";
import { useEffect } from "react";

export default function Message({ message, setMessage }) {
	useEffect(() => {
		if (message) {
			const timer = setTimeout(() => {
				setMessage(null);
			}, 2000);
			return () => clearTimeout(timer);
		}
	});
	return (
		<div
			id="message"
			style={{
				borderColor: message.error ? "#dc5050" : "#00732c",
			}}
		>
			{message.error && (
				<img
					style={{ backgroundColor: message.error ? "#dc5050" : "#00732c" }}
					id="message-icon"
					src={errorIcon}
					alt="Error"
				/>
			)}
			{!message.error && (
				<img
					style={{ backgroundColor: message.error ? "#dc5050" : "#00732c" }}
					id="message-icon"
					src={tickIcon}
					alt="Success"
				/>
			)}

			<p id="message-text">
				{message.message.length > 100
					? message.message.slice(0, 90) + "..."
					: message.message}
			</p>
			<img
				id="message-close"
				src={closeIcon}
				alt="Close Message"
				onClick={() => setMessage(null)}
			/>
		</div>
	);
}
