import { Fragment, useContext, useRef, useState } from "react";
import directoryIcon from "../../assets/directory.svg";
import actionIcon from "../../assets/action.svg";
import fileIcon from "../../assets/file.svg";
import pinnedIcon from "../../assets/pin.svg";
import { ALLOWED_PREVIEW } from "../../Constants/allowedPreview.js";
import ActionsFile from "../Actions/ActionsFile";
import ActionsDirectory from "../Actions/ActionsDirectory";
import Pagination from "../Tools/Pagination";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import "./style.css";
import Profile from "../profile/Profile";
import ManageAccess from "../ManageAccess/ManageAccess";
import Move from "../Move/Move";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../Constants/errorMessages.js";

export default function Content() {
	const filesColumns = useRef([
		"Select",
		"Name",
		"Creator",
		"Created At",
		"Actions",
	]);
	const dirColumns = useRef([
		"Select",
		"Name",
		"Creator",
		"Shared",
		"Created At",
		"Actions",
	]);

	const profileLocation = useRef(null);

	const {
		content,
		setDirectoryId,
		setSelectedFile,
		selectedFiles,
		setSelectedFiles,
		setPreviewUrl,
		selectedDirectories,
		setMessage,
		setSelectedDirectories,
		actionPosition,
		setActionPosition,
		totalPages,
		filePage,
		fileTotalPages,
		page,
		setFilePage,
		setPage,
	} = useContext(DataContext);
	const { directories, files } = content;
	const [showDirectoryActions, setShowDirectoryActions] = useState(false);
	const [showManageAccess, setShowManageAccess] = useState(false);
	const [showFileActions, setShowFileActions] = useState(false);
	const [showProfile, setShowProfile] = useState(false);
	const userId = useRef(null);
	const [showMove, setShowMove] = useState(false);
	const navigate = useNavigate();

	async function unPinDirectory(directoryId) {
		try {
			const { data } = await axios.patch(`/directory/unpin/${directoryId}`);
			if (data.success) {
				setMessage({ error: false, message: data.message });

				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} else {
				setMessage({ error: true, message: data.message });
			}
		} catch (error) {
			setMessage({ error: true, message: ERROR_MESSAGE });
		}
	}

	async function unPinFile(fileId) {
		try {
			const { data } = await axios.patch(`/file/unpin/${fileId}`);

			if (data.success) {
				setMessage({ error: false, message: data.message });
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} else {
				setMessage({ error: true, message: data.message });
			}
		} catch (error) {
			setMessage({ error: true, message: ERROR_MESSAGE });
		}
	}

	return (
		<Fragment>
			{showManageAccess && (
				<ManageAccess setShowManageAccess={setShowManageAccess} />
			)}
			{showMove && <Move setShowMove={setShowMove} />}
			{showDirectoryActions && (
				<ActionsDirectory
					X={actionPosition.X}
					Y={actionPosition.Y}
					setShowManageAccess={setShowManageAccess}
					setShowMove={setShowMove}
					setShowDirectoryActions={setShowDirectoryActions}
				/>
			)}
			{showFileActions && (
				<ActionsFile
					X={actionPosition.X}
					Y={actionPosition.Y}
					setShowMove={setShowMove}
					setShowFileActions={setShowFileActions}
				/>
			)}

			{showProfile && (
				<Profile
					userId={userId.current}
					X={profileLocation.current.X}
					Y={profileLocation.current.Y}
				/>
			)}

			{/* ======================= NOTHING TO SHOW ======================= */}
			{directories.length === 0 && files.length === 0 && (
				<div className="nothing-to-show">
					<p>Nothing to show here</p>
				</div>
			)}
			{/* ======================= DIRECTORY EXP ======================= */}
			{directories.length > 0 && (
				<Fragment>
					<Pagination
						title={"Directory"}
						totalPages={totalPages}
						page={page}
						setPage={setPage}
						selectedFiles={selectedFiles}
						selectedDirectories={selectedDirectories}
					/>
					<table className="explorer directories">
						<thead>
							<tr>
								{dirColumns.current.map((column, index) => {
									let width =
										index === dirColumns.current.length - 1 ? "auto" : "100px";
									if (index === 0) {
										width = "10px";
									}

									return (
										<th
											className={`${
												index !== 0 &&
												index !== 1 &&
												index !== dirColumns.current.length - 1
													? "hide-on-mobile"
													: ""
											}`}
											key={index}
											style={{
												width: width,
												textAlign:
													index === dirColumns.current.length - 1
														? "right"
														: "left",
											}}
										>
											{index === 0 ? (
												<input
													style={{ cursor: "pointer" }}
													type="checkbox"
													onClick={(e) => {
														e.target.checked
															? setSelectedDirectories(
																	directories.map((dir) => dir._id)
															  )
															: setSelectedDirectories([]);
													}}
												/>
											) : (
												column
											)}
										</th>
									);
								})}
							</tr>
						</thead>

						<tbody>
							{directories.map((directory) => {
								return (
									<tr
										className={`${
											selectedDirectories.includes(directory._id)
												? "selected-item"
												: ""
										} ${directory.pinned ? "pinned" : ""}`}
										key={directory._id}
										onDoubleClick={() => {
											navigate(`/home/${directory._id}`);
										}}
										onClick={() => {
											if (
												selectedDirectories.length > 0 ||
												selectedFiles.length > 0
											) {
												if (selectedDirectories.includes(directory._id)) {
													setSelectedDirectories(
														selectedDirectories.filter(
															(id) => id !== directory._id
														)
													);
												} else {
													setSelectedDirectories((prev) => {
														return [...prev, directory._id];
													});
												}
											}
										}}
									>
										<td>
											<input
												type="checkbox"
												style={{ cursor: "pointer" }}
												onChange={(e) => {
													if (e.target.checked) {
														setSelectedDirectories((prev) => {
															return [...prev, directory._id];
														});
													} else {
														const newSelected = selectedDirectories.filter(
															(id) => id !== directory._id
														);
														setSelectedDirectories(newSelected);
													}
												}}
												checked={selectedDirectories.includes(directory._id)}
											/>
										</td>
										<td
											style={{
												width: "200px",
												cursor: "pointer",
												display: "flex",
												alignItems: "center",
											}}
											title={`${directory.directoryName}`}
										>
											{directory.pinned && (
												<img
													className="pinned-icon"
													src={pinnedIcon}
													onClick={() => {
														unPinDirectory(directory._id);
													}}
													alt="Pinned"
												/>
											)}
											<img src={directoryIcon} alt="Directory" />
											<p
												style={{
													overflow: "scroll",
												}}
											>
												{directory.directoryName}
											</p>
										</td>
										<td className="hide-on-mobile">{directory.creatorEmail}</td>
										<td className="hide-on-mobile">
											<div
												style={{
													display: "flex",
													justifyContent: "flex-start",
													alignItems: "center",
												}}
											>
												{directory?.shared.length === 0
													? "-"
													: directory.shared.slice(0, 3).map((user, index) => {
															const bg = ["#1CDC52", "#1C93DC", "#DCA61C"];
															const style = {
																borderRadius: "50%",
																width: "25px",
																height: "25px",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
																background: bg[index],
																color: "white",
																transform: `translateX(-${index * 10}px)`,
																cursor: "pointer",
															};
															return (
																<div
																	key={index}
																	style={style}
																	onMouseEnter={(e) => {
																		profileLocation.current = {
																			X: e.clientX,
																			Y: e.clientY,
																		};
																		userId.current = user.user;
																		setShowProfile(true);
																	}}
																	onMouseLeave={() => {
																		setShowProfile(false);
																	}}
																>
																	{user.email[0].toUpperCase()}
																</div>
															);
													  })}
											</div>
										</td>
										<td
											className="hide-on-mobile"
											style={{
												color: "#616161",
											}}
										>
											{new Date(directory.createdAt).toLocaleDateString()}
										</td>
										<td>
											<img
												style={{
													marginLeft: "calc(100% - 20px)",
													marginTop: "10px",
												}}
												onClick={(e) => {
													setActionPosition(() => ({
														X: e.clientX - 240,
														Y: e.clientY + 20,
													}));
													setShowDirectoryActions(true);
													setSelectedFile(null);
													setDirectoryId(directory._id);
												}}
												src={actionIcon}
												className="actions-icon"
												alt="action icon"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</Fragment>
			)}

			{/* ======================= FILE EXP ======================= */}
			{files.length > 0 && (
				<Fragment>
					<Pagination
						title={"Files"}
						totalPages={fileTotalPages}
						page={filePage}
						setPage={setFilePage}
						selectedFiles={selectedFiles}
						selectedDirectories={selectedDirectories}
					/>
					<table className="explorer files">
						<thead>
							<tr>
								{filesColumns.current.map((column, index) => {
									let width =
										index === filesColumns.current.length - 1
											? "auto"
											: "100px";
									if (index === 0) {
										width = "10px";
									}

									return (
										<th
											className={`${
												index !== 0 &&
												index !== 1 &&
												index !== filesColumns.current.length - 1
													? "hide-on-mobile"
													: ""
											}`}
											key={index}
											style={{
												width: width,

												textAlign:
													index === filesColumns.current.length - 1
														? "right"
														: "left",
											}}
										>
											{index === 0 ? (
												<input
													style={{ cursor: "pointer" }}
													type="checkbox"
													onClick={(e) => {
														setSelectedFiles(
															e.target.checked
																? files.map((file) => file._id)
																: []
														);
													}}
												/>
											) : (
												column
											)}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{files.map((file) => {
								return (
									<tr
										key={file._id}
										className={`${
											selectedFiles.includes(file._id) ? "selected-item" : ""
										}  ${file.pinned ? "pinned" : ""}`}
										onClick={() => {
											if (
												selectedDirectories.length > 0 ||
												selectedFiles.length > 0
											) {
												if (selectedFiles.includes(file._id)) {
													setSelectedFiles(
														selectedFiles.filter((id) => id !== file._id)
													);
												} else {
													setSelectedFiles((prev) => {
														return [...prev, file._id];
													});
												}
											}
										}}
									>
										<td>
											<input
												type="checkbox"
												style={{ cursor: "pointer" }}
												onChange={(e) => {
													if (e.target.checked) {
														setSelectedFiles((prev) => {
															return [...prev, file._id];
														});
													} else {
														const newSelected = selectedFiles.filter(
															(id) => id !== file._id
														);
														setSelectedFiles(newSelected);
													}
												}}
												checked={selectedFiles.includes(file._id)}
											/>
										</td>
										<td
											style={{
												width: "200px",
												cursor: "pointer",
												display: "flex",
												alignItems: "center",
											}}
											onClick={() => {
												if (file.ACL === "public-read") {
													if (ALLOWED_PREVIEW.includes(file.contentType)) {
														setPreviewUrl({
															contentType: file.contentType,
															url: file.url,
														});
													} else {
														setPreviewUrl(null);
														setMessage({
															error: true,
															message: "Cannot preview this file",
														});
													}
												} else {
													setPreviewUrl(null);
													setMessage({
														error: true,
														message: "Cannot preview private files",
													});
												}
											}}
											title={`${file.fileName}`}
										>
											{file.pinned && (
												<img
													className="pinned-icon"
													src={pinnedIcon}
													onClick={() => {
														unPinFile(file._id);
													}}
													alt="Pinned"
												/>
											)}
											<img src={fileIcon} alt="File" />
											<p
												style={{
													overflow: "scroll",
													whiteSpace: "nowrap",
													width: "100%",
												}}
											>
												{file.fileName}
											</p>
										</td>
										<td className="hide-on-mobile">{file.creatorEmail}</td>
										<td
											className="hide-on-mobile"
											style={{
												color: "#616161",
											}}
										>
											{new Date(file.createdAt).toLocaleDateString()}
										</td>
										<td>
											<img
												style={{
													marginLeft: "calc(100% - 20px)",
													marginTop: "10px",
												}}
												onClick={(e) => {
													setActionPosition(() => ({
														X: e.clientX - 240,
														Y: e.clientY + 20,
													}));
													setSelectedFile(file);
													setDirectoryId(null);
													setShowFileActions(true);
												}}
												src={actionIcon}
												className="actions-icon"
												alt="action icon"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</Fragment>
			)}
		</Fragment>
	);
}
