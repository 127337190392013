import React, { Fragment, useContext, useEffect, useState } from "react";
import Nav from "../../components/Nav/Nav";
import { DataContext } from "../../context/DataContext";
import deleteIcon from "../../assets/delete.svg";
import axios from "axios";
import Message from "../../components/Message/Message";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import "./style.css";

export default function Users() {
	const [users, setUsers] = useState(null);
	const [usersToBeDeleted, setUsersToBeDeleted] = useState([]);
	const { message, setMessage } = useContext(DataContext);

	useEffect(() => {
		async function getUsers() {
			try {
				const { data } = await axios.get("/users");

				if (data.success) {
					setUsers(data.data.users);

					setMessage({
						error: false,
						message: data.message,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		getUsers();
	}, []);

	return (
		<Fragment>
			{message && <Message message={message} setMessage={setMessage} />}

			<Nav />
			<div id="users">
				<ul>
					<h1>USERS</h1>
					{users?.map((user, index) => {
						const selected = usersToBeDeleted.includes(user._id);
						const style = {};

						if (selected) {
							style.backgroundColor = "rgb(166, 72, 23)";
							style.color = "white";
						}

						return (
							<li key={index} style={style}>
								<div>
									<p>
										<strong>{user.fullName}</strong>
									</p>
									<span style={{ fontSize: "13px" }}>
										<strong>Email: </strong>
										{user.email}
									</span>
									<span style={{ fontSize: "13px" }}>
										<strong>Role: </strong>
										{user.role}
									</span>
								</div>

								<div>
									<img
										src={deleteIcon}
										alt="delete user"
										onClick={(e) => {
											if (usersToBeDeleted.includes(user._id)) {
												setUsersToBeDeleted(
													usersToBeDeleted.filter((id) => id !== user._id)
												);
											} else {
												setUsersToBeDeleted([...usersToBeDeleted, user._id]);
											}
											e.target.classList.toggle("selected-for-deletion");
										}}
									/>
								</div>
							</li>
						);
					})}
				</ul>

				{usersToBeDeleted.length > 0 && (
					<button id="delete-users" type="submit">
						Delete Users
					</button>
				)}
				{!users && (
					<h1
						style={{
							fontSize: "20px",
							color: "red",
						}}
					>
						You don't have access!
					</h1>
				)}
			</div>
		</Fragment>
	);
}
