import { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import { DataContext } from "../../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function Navigation() {
	const [path, setPath] = useState(null);
	const { setMessage } = useContext(DataContext);
	const { id: currentDirectoryId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchDetails() {
			if (currentDirectoryId === "shared") {
				return;
			}

			try {
				if (!currentDirectoryId) {
					return;
				}
				const { data } = await axios.get(
					`/directory/details/${currentDirectoryId}`
				);

				if (data.success) {
					setPath(data.data.directory.hierarchy);
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		fetchDetails();
	}, [currentDirectoryId]);

	return (
		<Fragment>
			{path && (
				<ul id="navigation">
					{path?.map((hierarchy, index) => {
						return (
							<Fragment key={index}>
								<li
									className="navigation-link"
									onClick={() => {
										navigate(`/home/${hierarchy._id}`);
									}}
								>
									{hierarchy.directoryName}
								</li>

								<li>{" > "}</li>
							</Fragment>
						);
					})}
				</ul>
			)}
		</Fragment>
	);
}
