import React, { useRef, useState } from "react";
import axios from "axios";
import { Fragment, useContext, useEffect } from "react";
import Message from "../../components/Message/Message";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import httpStatusCodes from "../../Constants/httpStatusCodes";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import Nav from "../../components/Nav/Nav";
import LoadingUsersState from "./LoadingUsersState";
import "./style.css";

function Users({ sharingUsers }) {
	const columns = useRef(["Email", "Full Name", "Role", "Files"]);
	const navigate = useNavigate();

	if (sharingUsers.length === 0) {
		return (
			<h2 id="sharing-heading">
				No users are sharing this workspace with you.
			</h2>
		);
	}

	return (
		<Fragment>
			<h3 id="sharing-heading">Users sharing with you:</h3>
			<div className="sharing-user-workspace-container">
				<table className="sharing-user-workspace">
					<thead>
						<tr>
							{columns.current.map((column, index) => {
								let width = index === 0 ? "auto" : "150px";

								return (
									<th
										key={column}
										className={
											index === 1 || index === 2 ? "hide-on-mobile" : ""
										}
										style={{
											width: width,
											textAlign: "left",
										}}
									>
										{column}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{sharingUsers.map((workspace) => {
							return (
								<tr
									key={workspace._id}
									className="user"
									onClick={() => navigate(`/shared/${workspace._id}`)}
								>
									<td className="email">{workspace.email}</td>
									<td className="other-info hide-on-mobile">
										{" "}
										{workspace.fullName}
									</td>
									<td className="other-info hide-on-mobile">
										{workspace.role}
									</td>
									<td className="other-info">{workspace.fileCount}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
}

export default function UsersShared() {
	const { message, setMessage } = useContext(DataContext);
	const [sharingUsers, setSharingUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchData() {
			try {
				setLoading(true);
				const { data } = await axios.get("/workspace/users", {
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				});

				if (data.statusCode === httpStatusCodes.OK) {
					setSharingUsers(data.data.sharedWorkspace);
				} else if (data.statusCode === httpStatusCodes.UNAUTHORIZED) {
					setMessage({
						error: true,
						message: data.message,
					});

					localStorage.clear();
					navigate("/login");
				} else {
					setMessage({
						error: true,
						message: data.message,
					});

					navigate("/home");
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, []);

	return (
		<Fragment>
			<Nav />
			{message && <Message message={message} setMessage={setMessage} />}

			{loading && <LoadingUsersState />}

			<div id="sharing-user">
				{sharingUsers && <Users sharingUsers={sharingUsers} />}
			</div>
		</Fragment>
	);
}
