export const ALLOWED_PREVIEW = [
	"image/png",
	"image/jpeg",
	"image/jpg",
	"image/gif",
	"image/svg+xml",

	"video/quicktime",
	"video/mp4",
	"video/mov",

	"audio/mpeg",
	"audio/ogg",
	"audio/wav",
	"audio/flac",
	"audio/aac",
	"audio/mp4",
	"audio/webm",
	"audio/3gpp",
	"audio/3gpp2",
	"audio/mpeg",
];
