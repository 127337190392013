import React, { useContext, useEffect, useRef } from "react";
import { SearchContext } from "../../context/SearchContext";
import { useNavigate } from "react-router-dom";

export default function SearchResults({
	searchFilesAndDirectories,
	page,
	searchRef,
}) {
	const { searchResults, setSearchResults } = useContext(SearchContext);
	const searchResultsRef = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		const searchResults = searchResultsRef.current;

		if (searchResults) {
			searchResults.addEventListener("scroll", (event) => {
				const { scrollTop, scrollHeight, clientHeight } = event.target;

				if (scrollTop + clientHeight >= scrollHeight) {
					page.current = page.current + 1;
					searchFilesAndDirectories(page.current);
				}
			});
		}
	}, []);

	return (
		<ul className="search-results" ref={searchResultsRef}>
			<div id="hide-search-results">
				<button
					onClick={() => {
						setSearchResults({
							files: [],
							directories: [],
						});

						searchRef.current.value = "";
					}}
				>
					Hide
				</button>
			</div>
			{searchResults.files.map((file) => {
				const { _id, fileName, url, hierarchy } = file;
				return (
					<li
						key={_id}
						className="file"
						onDoubleClick={() => {
							window.location.href = url;
						}}
					>
						<p>{fileName}</p>
						<ul style={{ listStyleType: "none" }} className="path">
							{hierarchy.map((directory, index) => (
								<li
									key={index}
									className="path-element"
									onClick={() => {
										setSearchResults({
											files: [],
											directories: [],
										});

										searchRef.current.value = "";

										navigate(`/home/${directory._id}`);
									}}
									title={`${directory.directoryName}`}
								>
									{directory.directoryName}
								</li>
							))}
						</ul>
					</li>
				);
			})}

			{searchResults.directories.map((directory) => {
				const { _id, directoryName, hierarchy } = directory;

				return (
					<li
						key={_id}
						className="file"
						onDoubleClick={() => {
							navigate(`/home/${_id}`);
							setSearchResults({
								files: [],
								directories: [],
							});
							searchRef.current.value = "";
						}}
					>
						<p>{directoryName}</p>
						<ul className="path" style={{ listStyleType: "none" }}>
							{hierarchy.map((directory, index) => (
								<li
									key={index}
									className="path-element"
									onClick={() => {
										navigate(`/home/${directory._id}`);

										setSearchResults({
											files: [],
											directories: [],
										});

										searchRef.current.value = "";
									}}
									title={`${directory.directoryName}`}
								>
									{"> " +
										(directory.directoryName.length > 17
											? directory.directoryName.slice(0, 17)
											: directory.directoryName + "...")}
								</li>
							))}
						</ul>
					</li>
				);
			})}
		</ul>
	);
}
