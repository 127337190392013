import { Fragment, useContext, useRef, useState } from "react";
import petalStudioIcon from "../../assets/petalstudio.svg";
import { DataContext } from "../../context/DataContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Message from "../../components/Message/Message";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function ResetPassword() {
	const [newPassword, setNewPassword] = useState("");
	const [verifyPassword, setVerifyPassword] = useState("");
	const navigate = useNavigate();

	const id = useRef(new URLSearchParams(window.location.search).get("id"));
	const token = useRef(
		new URLSearchParams(window.location.search).get("token")
	);

	const { setMessage, message } = useContext(DataContext);

	async function resetPassword(e) {
		e.preventDefault();

		if (!newPassword || !verifyPassword) {
			setMessage({
				error: true,
				message: "Please enter your new password",
			});
			return;
		}

		if (newPassword !== verifyPassword) {
			setMessage({
				error: true,
				message: "Passwords do not match",
			});
			return;
		}

		if (!id.current || !token.current) {
			setMessage({
				error: true,
				message: "Invalid reset link",
			});
			return;
		}

		try {
			const { data } = await axios.post(
				"/reset-password",
				{
					password: newPassword,
					id: id.current,
				},
				{
					headers: {
						Authorization: token.current,
					},
				}
			);

			if (data.success) {
				setMessage({
					error: false,
					message: data.message,
				});
			} else {
				setMessage({
					error: true,
					message: data.message,
				});
			}

			setTimeout(() => {
				navigate("/login");
			}, 1000);
		} catch (error) {
			setMessage({
				error: true,
				message: ERROR_MESSAGE,
			});
		}
	}

	return (
		<Fragment>
			{message && <Message message={message} setMessage={setMessage} />}

			<form id="forgot-password-form" onSubmit={(e) => resetPassword(e)}>
				<Link to={"/"} id="login-logo">
					<img src={petalStudioIcon} alt="logo" />
					<span>Petal Studio</span>
				</Link>

				<h1>Reset Password</h1>

				<input
					autoFocus
					type="password"
					placeholder="Password"
					onChange={(e) => setNewPassword(e.target.value)}
					required
				/>

				<input
					type="password"
					placeholder="Re-Enter Password"
					onChange={(e) => setVerifyPassword(e.target.value)}
					required
				/>

				<div id="forgot-password">
					<Link to={"/login"}>Login?</Link>
				</div>
				<button>Reset Password</button>
			</form>
		</Fragment>
	);
}
