import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import axios from "axios";
import { DataContext } from "../../context/DataContext";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function Profile({ userId, X, Y }) {
	const [user, setUser] = useState({
		fullName: "Loading...",
		email: "Loading...",
		role: "Loading...",
	});
	const profile = useRef();
	const { setMessage } = useContext(DataContext);

	useEffect(() => {
		if (profile.current) {
			profile.current.style.left = `${X - 20}px`;
			profile.current.style.top = `${Y + 10}px`;
		}

		async function fetchUser() {
			try {
				const { data } = await axios.get(`/users/${userId}`);

				if (data.success) {
					setUser(data.data.user);
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		fetchUser();
	}, []);

	return (
		<Fragment>
			<div ref={profile} className="profile">
				<h3>Profile</h3>
				<p>
					<strong>Name:</strong> {user.fullName}
				</p>
				<p>
					<strong>Email:</strong> {user.email}
				</p>
				<p>
					<strong>Role:</strong> {user.role}
				</p>
			</div>
		</Fragment>
	);
}
