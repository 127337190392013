import { Fragment, useContext, useEffect } from "react";
import axios from "axios";
import Tools from "../Tools/Tools";
import Content from "./Content";
import Message from "../Message/Message";
import Details from "../Details/Details";
import LoadingState from "./LoadingState";
import Player from "../Player/Player";
import { ProgressBar } from "../Tools/UploadFile";
import { DataContext } from "../../context/DataContext";
import "./style.css";
import SearchContextProvider from "../../context/SearchContext";
import Navigation from "../Tools/Navigation";
import { useNavigate, useParams } from "react-router-dom";
import httpStatusCodes from "../../Constants/httpStatusCodes";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import Operations from "../Tools/Operations";

export default function Explorer() {
	const {
		sort,
		order,
		limit,
		page,
		message,
		setMessage,
		loading,
		setLoading,
		setTotalPages,
		setContent,
		viewDetails,
		uploadingFileProgress,
		previewUrl,
		setPreviewUrl,
		setFileTotalPages,
		filePage,
		selectedFiles,
		selectedDirectories,
	} = useContext(DataContext);
	const { id: currentDirectoryId = null } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchData() {
			let url = `/workspace/${currentDirectoryId}`;

			try {
				setLoading(true);
				const { data } = await axios.get(url, {
					params: {
						sort: sort,
						limit: limit,
						page: page,
						order: order,
						filePage: filePage,
					},

					headers: {
						Authorization: localStorage.getItem("token"),
					},
				});

				if (data.statusCode === httpStatusCodes.OK) {
					setTotalPages(data.data.pages);
					setFileTotalPages(data.data.filePages);

					setContent(() => ({
						containsFiles: data.data.files.length > 0,
						directories: data.data.directories,
						files: data.data.files,
						pages: data.data.pages,
					}));
				} else if (data.statusCode === httpStatusCodes.UNAUTHORIZED) {
					setMessage({
						error: true,
						message: data.message,
					});

					localStorage.clear();
					navigate("/login");
				} else {
					setMessage({
						error: true,
						message: data.message,
					});

					navigate("/home");
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [
		sort,
		order,
		limit,
		page,
		setMessage,
		filePage,
		setLoading,
		setContent,
		setTotalPages,
		currentDirectoryId,
	]);

	return (
		<Fragment>
			<SearchContextProvider>
				<Tools />
			</SearchContextProvider>

			<Navigation />

			{selectedFiles &&
				(selectedFiles.length > 0 || selectedDirectories.length > 0) && (
					<Operations />
				)}

			{previewUrl && <Player url={previewUrl} setUrl={setPreviewUrl} />}

			{message && <Message message={message} setMessage={setMessage} />}

			<div className={`table-container ${viewDetails ? "shrink" : ""}`}>
				{!loading && <Content />}

				{loading && <LoadingState />}

				{viewDetails && <Details />}
			</div>

			{uploadingFileProgress && <ProgressBar />}
		</Fragment>
	);
}
